.Center{
  display: 'flex' !important;
  align-items: 'center' !important;
  justify-content: 'center' !important;
  height: '100%' !important;
}
.bar{
  margin: 0 auto !important;
  width: 30vw !important;
  border-radius: 30px !important;
  background-color: #EDF2FC !important;
}
.bar:hover{
  box-shadow: 1px 1px 5px 1px #dcdcdc !important;
}
.bar:focus-within{
  box-shadow: 1px 1px 5px 1px #dcdcdc !important;
  outline:none !important;
}
.searchbar{
  height: 5vh !important;
  border:none !important;
  width: 26vw !important;
  outline: none !important;
}
.searchbar2{
  height: 100% !important;
  border:none !important;
  width: 100% !important;
  outline: none !important;
}

.colore1{
  background-color: #D9C2B4 !important;
}
.colore2{
  background-color: #FFF4ED !important;
}
.colore3{
  background-color: #EDD4C5 !important;
}
.colore4{
  background-color: #659FA1 !important;
}
.colore5{
  background-color: #C5ECED !important;
} 
.colore6{
 background-color:   #ECECEC !important;
}

.indCard:hover{
  background-color: #E1E5EA !important;
}

.containerPrincipale{
  height: 93vh;
  /*background-color: #F1E3E3;
  background-image: url('images/background/bg2.png');
  background-size: cover*/

}

.my-modal {
  width: 95vw !important;  /* Occupy the 90% of the screen width */
  max-width: 95vw !important  ;
  height: 90vh !important;
  max-height: 90vh !important;
} 

.removeArrow::after {
  display: none !important; 
}

/*BARRA SCORRIMENTO*/
::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 15px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.temaMyLabanof{
  height: 100vh;
  background: rgb(231,206,206);
  background: linear-gradient(360deg, rgba(231,206,206,1) 0%,rgb(247, 249, 252, 1) 90%, rgb(247, 249, 252, 1) 100%);
  background-size: cover;
  user-select: none;
}
.temaMyLabanof .indCard:hover{
  background-color: #7FBDFF !important;
}
.temaMyLabanof .table-hover tbody tr:hover td, .table-hover tbody tr:hover th {
  background-color: #c9e3ff;
}

.temaPastelloArancio{
  height: 100vh;
  background: #FED6A5;
background: linear-gradient(360deg, rgba(254,214,165,1) 0%, rgb(247, 249, 252, 1) 90%, rgb(247, 249, 252, 1) 100%);
 background-size: cover;
  user-select: none;
}


.temaPastelloVerde{
  height: 100vh;
  background: #CEE5B7;
  background: linear-gradient(360deg, rgba(206,229,183,1) 0%, rgb(247, 249, 252, 1) 90%, rgb(247, 249, 252, 1) 100%);
  user-select: none;
}


.temaPastelloAzzurro{
  height: 100vh;
  background: #A9DFEB;
  background: linear-gradient(360deg, rgba(169,223,235,1) 0%, rgb(247, 249, 252, 1) 90%, rgb(247, 249, 252, 1) 100%);  
  user-select: none;
}


.temaPastelloBlue{
  height: 100vh;
  background: #A5C1E6;
  background: linear-gradient(360deg, rgba(165,193,230,1) 0%, rgb(247, 249, 252, 1) 90%, rgb(247, 249, 252, 1) 100%);
  background-size: cover;
  user-select: none;
} 

.temaPastelloRosa{
  height: 100vh;
  background: #EBC8DE;
  background: linear-gradient(360deg, rgba(235,200,222,1) 0%, rgb(247, 249, 252, 1) 90%, rgb(247, 249, 252, 1) 100%);
  background-size: cover;
  user-select: none;
}


@import url('https://fonts.googleapis.com/css2?family=Lato&family=Montserrat&family=Mulish&family=Open+Sans&family=Poppins&family=Roboto&display=swap');
.Roboto {
  font-family: 'Roboto' !important;
}
.OpenSans{
  font-family: 'Open Sans' !important;
}
.Lato{
  font-family: 'Lato' !important;
}
.Montserrat{
  font-family: 'Montserrat' !important;
}
.Poppins{
  font-family: 'Poppins' !important;
}
.Mulish{
  font-family: 'Mulish' !important;
}

/*
#f7abad
#fed6a5
#faf6b7
#CEE5B7

#A9DFEB


#A5C1E6

#EBC8DE







*/